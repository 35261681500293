import React, { useState } from "react";
import "./index.css";
import "./App.scss";
import Login from "./login/Login";
import Accordian from "./accordian/Accordian";

import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Logs from "./pages/Logs";
import Newproposal from "./pages/Newproposal";
import UserManagement from "./pages/UserManagement";
import Quotes from "./pages/Quotes";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/invoice_logs" element={<Logs />}></Route>
          <Route path="/proposal" element={<Newproposal />}></Route>
          <Route path="/usermanagement" element={<UserManagement />}></Route>
          <Route path="/Quotes" element={<Quotes />} />
        </Route>


      </Routes>
    </div>
  );
};

export default App;
