import { Page, Document, Image, StyleSheet, View, Text } from "@react-pdf/renderer";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import firstPageBgImage from "../assets/images/Artboard111.png";
import akkuFirstPageBgImage from "../assets/images/akku1.png";

import logo from "../assets/images/cnw-logo.png";
import secoundPageBottomImg from "../assets/images/secoundPageBottomImg.png";
import thankyouImage from "../assets/images/thankyou-image.png";
import thirdPageBgImage from "../assets/images/third-page-bg-image.png";

import signatureImgae from "../assets/images/signatureImgae.png";

import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import AkkuInvoiceItemsTable from "./AkkuInvoiceItemsTable";
import styles from "./pdfStyle"; // Importing the styles
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const PdfView = (props) => {

  const { isOpenPopup, handleClose, allFormData, pdfType } = props;
  const [open, isOpen] = useState(false);
  const navigate = useNavigate();

  // Function to format date to "DD MMM YYYY"
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const month = monthNames[date.getMonth()]; // Get month name
    const year = date.getFullYear(); // Get year

    return `${day} ${month} ${year}`; // Return formatted date
  };

  // Get today's date (estimate date)
  const estimateDate = new Date();
  const formattedEstimateDate = formatDate(estimateDate);

  useEffect(() => { }, []);

  useEffect(() => {
    isOpen(isOpenPopup);
  }, [isOpenPopup]);

  const handleCloseModal = () => {
    handleClose();
    navigate("/invoice_logs");
  };
  const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };

  const formatDate1 = (dateStr) => {
    const parsedDate = dayjs(dateStr, "DD-MM-YYYY");
    return parsedDate.isValid() ? parsedDate.format("DD MMM YYYY").toUpperCase() : "Invalid Date";
  };

  return (
    <Modal className="popup-doc  rounded-lg" centered open={open} footer={false} onCancel={handleCloseModal}>
      <PDFViewer width="900" height="830">
        <Document>
          <Page size="A4" style={styles.pageFirst}>
            <View style={styles.clientLogo}>
              <View style={{ position: "absolute", top: 0, width: "100%", height: 850, zIndex: 999 }}>
                {/* <Image style={styles.pageFirstBackgroundImage} src={firstPageBgImage} /> */}
                <Image style={styles.pageFirstBackgroundImage} src={pdfType == 'GWS' ? firstPageBgImage : akkuFirstPageBgImage} />
              </View>
              {/* 520 it is GWS */}
              <View style={{ top: pdfType == 'GWS' ? 520 : 395, position: "absolute", width: "100%", zIndex: 0, display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {/* <Image style={styles.pageFirstBackgroundImage} src={firstPageBgImage} /> old */}
                <View style={{ borderWidth: 1, borderColor: "#B2B2B2", padding: 10 }}>
                  <Text style={styles.customerName}>{allFormData.customerCompanyName}</Text>
                </View>
              </View>
            </View>
          </Page>

          <Page size="A4" style={styles.pageSecond}>
            <View style={styles.pageSecondContent1}>
              <View style={styles.pageSecondHeader}>
                <View style={styles.pageSecondHeaderLogo}>
                  <Image src={logo} />
                </View>
              </View>
              <View style={styles.pageSecondRow}>
                <View style={styles.pageSecondLeft}>
                  <View style={styles.cnwOrganisationAddress}>
                    <Text style={styles.name}>{"Madhav Sattanathan"}</Text>
                    <Text style={styles.designation}>{"CEO"}</Text>
                    <Text style={styles.streetNumber}>
                      <Text style={styles.textIndia}>INDIA</Text>
                      {": No 6, 13, North Ave,"}
                    </Text>
                    <Text style={styles.place}>{"Kesavaperumalpuram"}</Text>
                    <Text style={styles.area}>{"Krishnapuri, Raja Annamalai Puram,"}</Text>
                    <Text style={styles.statePincode}>{"Chennai, Tamil Nadu 600028. "}</Text>
                  </View>
                </View>
                <View style={styles.pageSecondRight}>
                  <View style={styles.pageSecondRightContent}>
                    <Text style={styles.dearContent}>Dear {titleCase(allFormData.customerName)},</Text>
                    <Text style={styles.paragraph1}>
                      I would like to personally thank you for considering CloudNow Technologies as your provider for Google Workspace. We have successfully partnered with Google for more than 10
                      years and are steadfast in continuing our growth journey with them.
                    </Text>
                    <Text style={styles.paragraph2}>
                      Over the last 10 years, we are proud to have been trusted partners to more than 500 companies across the country, and we have often been able to complement Google’s services with
                      our own expertise, providing solutions covering application development, consulting services, cybersecurity, and cloud infrastructure
                    </Text>
                    <Text style={styles.paragraph2Management}>management.</Text>
                    <Text style={styles.paragraph3}>
                      We look forward to serving you for many years and helping your business grow by building and optimising your technological needs. Please do keep us in mind in the future when you
                      want to discuss anything related to tech!
                    </Text>
                    <Text style={styles.paragraph4}>Thank you for your business, and we look forward to serving you.</Text>

                    <Image style={styles.signatureLogo} src={signatureImgae} />
                    <Text style={styles.name}>{"Madhav Sattanathan"}</Text>
                    <Text style={styles.designation}>{"CEO"}</Text>
                    <Text style={styles.companyname}>{"CloudNow Technologies"}</Text>
                    <Text style={styles.mailAddress}>{"madhav@cloudnowtech.com"}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.pageSecondContent2}>
              <Image src={secoundPageBottomImg} />
            </View>
          </Page>
          <Page size="A4" style={styles.pageThird}>
            <Image style={styles.pageThirdBackgroundImage} src={thirdPageBgImage} />
          </Page>
          <Page size="A4" style={styles.pageFour}>
            <View style={styles.header}>
              <View style={styles.titleContainer}>
                <Text style={styles.reportTitle}>Estimate</Text>
                <Text style={styles.reportId}>{"#EST-004453"}</Text>
              </View>
              <View style={styles.pageSecondHeaderLogo}>
                <Image src={logo} />
              </View>
            </View>

            <View style={styles.headerContainer}>
              <Text style={styles.fromCompany}>{"CloudNow Technologies Pvt. Ltd"}</Text>
              <Text style={styles.text1}>{"CIN - U74999TN2014PTC096441"}</Text>
              <Text style={styles.text2}>{"No.61,Chamiers House,Chamiers Road,R.A Puram"}</Text>
              <Text style={styles.text3}>{"Chennai Tamil Nadu 600028"}</Text>
              <Text style={styles.text4}>{"India"}</Text>
              <Text style={styles.text5}>{"GSTIN - 33AAFCC7000E1ZC"}</Text>
            </View>
            <View style={styles.headerContainer2}>
              <View style={styles.content1}>
                <Text style={styles.billTo}>Bill To:</Text>
                <Text style={styles.toCompany}>{allFormData.customerCompanyName}</Text>

                <Text style={styles.textLite}>{allFormData.billto.address}</Text>
                {
                  allFormData.billto.city && <Text style={styles.textLite}>{allFormData.billto.city}</Text>
                }
                <Text style={styles.textLite}>{allFormData.billto.country}</Text>
                {
                  allFormData.billto.gstinNumber && <Text style={styles.textLite}>GSTIN - {allFormData.billto.gstinNumber}</Text>
                }
                <Text style={styles.placeOfSupply}>
                  Place Of Supply: {allFormData.billto.stateName} ({allFormData.billto.state})
                </Text>
              </View>
              <View style={styles.content2}>
                <Text style={styles.estimateDate}>
                  Estimate Date: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={styles.dateValue}>{formattedEstimateDate}</Text>
                </Text>
                <Text style={styles.expiryDate}>
                  Expiry Date: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Text style={styles.dateValue}> {formatDate1(allFormData.billto.expiryDate)}</Text>
                </Text>
              </View>
            </View>

            <AkkuInvoiceItemsTable pdfData={allFormData} />

            <View style={styles.notesContainer}>
              <Text style={styles.notesTitle}>Notes</Text>
              <Text style={styles.notesDescription}>No TDS has to be deducted from the payment of Google Services.</Text>
            </View>

            <View style={styles.notesContainer}>
              <Text style={styles.notesTitle}>Terms & Conditions</Text>
              <Text style={styles.notesDescription}>{allFormData.billto.terms}</Text>
            </View>
          </Page>

          <Page size="A4" style={styles.pageThankyou}>
            <Image style={styles.pageThankyouBackgroundImage} src={thankyouImage} />
          </Page>
        </Document>
      </PDFViewer>
    </Modal>
  );
};

export default PdfView;
