// https://api.gwscalculator.cloudnowtech.com
// const apiURL = 'https://api.gwscalculator.cloudnowtech.com/';
// https://dev-api.gwscalculator.cloudnowtech.com/getRoleList.php
// process.env.https://dev-api.gwscalculator.cloudnowtech.com/;

// UI

// Dev : https://dev-pricingcalculator.cloudnowtech.com/
// Prod : https://pricingcalculator.cloudnowtech.com/


/* Dev */
// export const apiUrl = 'https://dev-api.gwscalculator.cloudnowtech.com/';

/* Prod */
// export const apiUrl = 'https://api.gwscalculator.cloudnowtech.com/';


const baseUrl = process.env.REACT_APP_BASEURL;
const environment = process.env.REACT_APP_BASEURL;
export const apiUrl = baseUrl;
