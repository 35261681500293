import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { spiltRupees } from "./formConfig"; // import configurations
import rupee from "../assets/rupee.png";
const borderColor = "#fff";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    // borderBottomColor: '#dddddd',
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
  totalContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  totalCalculationContainer: {
    width: "30%",
    paddingRight: 10,
  },

  totalContainerColor: {
    width: 250,
    backgroundColor: "#F3CCDD",
    paddingLeft: 107,
    paddingRight: 10,
    paddingTop: 3,
    paddingBottom: 3,
  },
  totalCalculation: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 6,
    marginBottom: 6,
    flexWrap: "wrap",
  },
  totalLabel: {
    // fontFamily: "Inter",
    color: "#0C151F",
    fontWeight: "medium",
    fontSize: 8,
  },
  totalCalculations: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  totalValue: {
    // fontFamily: "Inter",
    fontWeight: "medium",
    fontSize: 9,
    color: "#202020",
  },
  rupeeLog: {
    color: "black",
    height: "9px",
    width: "9px",
    fontWeight: "medium",
    fontSize: 9,
  },
  totalValueName: {
    // fontFamily: "Inter",
    fontWeight: "semibold",
    fontSize: 9,
    color: "#202020",
  },
  totalContainerWord: {
    width: 240,
  },
  totalWordLabel: {
    width: 70,
    fontWeight: "medium",
    fontSize: 9,
    color: "#333",
  },
  totalWord: {
    // fontFamily: "Inter",
    fontWeight: "extrabold",
    fontSize: 9,
    width: 160,
    color: "#202020",
  },
  tableFooter: {
    // borderBottomColor: "#ddd",
    // borderBottomWidth: 1,
    paddingBottom: 20,
    width: "100%",
  },
});

const InvoiceTableFooter = (props) => {

  return (
    <View style={styles.tableFooter}>
      <View style={styles.totalContainer}>
        <View style={styles.totalCalculationContainer}>
          <View style={styles.totalCalculation}>
            <Text style={styles.totalLabel}>Sub Total</Text>
            <View style={styles.totalCalculations}>
              <Image style={styles.rupeeLog} src={rupee} />
              <Text style={styles.totalValue}>{spiltRupees(props.pdfData.totals.subTotal)}</Text>
            </View>
          </View>

          {props.pdfData.billto.state == "33" ? (
            <>
              <View style={styles.totalCalculation}>
                <Text style={styles.totalLabel}>CGST (9%)</Text>
                <View style={styles.totalCalculations}>
                  <Image style={styles.rupeeLog} src={rupee} />
                  <Text style={styles.totalValue}>{spiltRupees(props.pdfData.totals.total_CGST)}</Text>
                </View>
              </View>
              <View style={styles.totalCalculation}>
                <Text style={styles.totalLabel}>SGST (9%) </Text>
                <View style={styles.totalCalculations}>
                  <Image style={styles.rupeeLog} src={rupee} />
                  <Text style={styles.totalValue}>{spiltRupees(props.pdfData.totals.total_SGST)}</Text>
                </View>
              </View>
            </>
          ) : (
            <View style={styles.totalCalculation}>
              <Text style={styles.totalLabel}>IGST (18%) </Text>
              <View style={styles.totalCalculations}>
                <Image style={styles.rupeeLog} src={rupee} />
                <Text style={styles.totalValue}>{spiltRupees(props.pdfData.totals.total_IGST)}</Text>
              </View>
            </View>
          )}
        </View>
      </View>

      <View style={styles.totalContainer}>
        <View style={styles.totalContainerColor}>
          <View style={styles.totalCalculation}>
            <Text style={styles.totalValueName}>Total</Text>
            <View style={styles.totalCalculations}>
              <Image style={styles.rupeeLog} src={rupee} />
              <Text style={styles.totalValue}>{spiltRupees(props.pdfData.totals.Total)}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.totalContainer}>
        <View style={styles.totalContainerWord}>
          <View style={styles.totalCalculation}>
            <Text style={styles.totalWordLabel}>Total In Words: </Text>
            <Text style={styles.totalWord}>{props.pdfData.totals.words}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default InvoiceTableFooter;
