import React, { useEffect, useState } from "react";
import img from "../assets/images/logo.png";
import type { MenuProps } from "antd";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import usermanagementPng from "../assets/userManagement.png";
import LogoutPng from "../assets/logout.png";
import person from "../assets/person.png";

function getFirstCharacter(input: string | null | undefined): string {
  if (input == null || input.length === 0) {
    return "";
  }
  return input.charAt(0);
}

const Header = () => {
  const [name, setName] = useState<any>(null);
  const [hasUserManagementAccess, setHasUserManagementAccess] = useState<any>(false); // Example state for user access

  useEffect(() => {
    // Example: Fetch user permissions or roles from sessionStorage or API
    const userPermissions: any = sessionStorage.getItem("userRoleId"); // Example: Fetch user permissions
    if (userPermissions == "1" || userPermissions == "3") {
      setHasUserManagementAccess(true);
    }

    setName(sessionStorage.getItem("userName")); // Fetch user name from sessionStorage

    setName(sessionStorage.userName);
  }, []);

  const menuItems = [
    {
      key: "1",
      label: (
        <Link to="/login" className="flex space-x-2 xl:space-x-3 pt-1 xl:pt-3 pb-1 xl:pb-3">
          <img className="w-[23px] xl:w-[28px]" src={LogoutPng} alt="logout" />
          <p className="font-medium text-[14px] xl:text-[18px]">Logout</p>
        </Link>
      ),
    },
  ];

  if (hasUserManagementAccess) {
    menuItems.unshift({
      key: "2",
      label: (
        <Link to="/usermanagement" className="flex space-x-2 xl:space-x-3 pr-3 xl:pr-7  pt-1 xl:pt-3">
          <img className=" w-[23px] xl:w-[28px]" src={usermanagementPng} alt="usermanagent" />
          <p className="font-medium text-[14px] xl:text-[18px]">User Management</p>
        </Link>
      ),
    });
  }

  return (
    <nav className="nav py-1 xl:py-2 2xl:py-3">
      <div className="mx-auto md:w-full px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>

              <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>

              <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <Link to="/invoice_logs">
                <img className=" h-4 lg:h-5 xl:h-6 2xl:h-8 w-auto cursor-pointer" src={img} alt="Your Company" />
              </Link>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex flex-col items-start pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div>
              <p className=" font-[Poppins] text-[14px] xl:text-[16px]">Welcome,</p>
            </div>
            <div>{name && <h2 className=" nav-name">{name}</h2>}</div>
          </div>
          <div className="relative ml-3">
            <div>
              <Dropdown overlay={<Menu items={menuItems} />} trigger={["click"]}>
                <button type="button" className="relative flex rounded-full text-sm cursor-pointer" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                  <span className="absolute -inset-1.5"></span>
                  <span className="sr-only">Open user menu</span>
                  <p className="bg-[#BD44CA] text-white text-[25px] xl:text-[30px]  font-[Poppins] font-medium w-[45px] xl:w-[55px] h-[45] xl:h-[55px] rounded-[50%] flex items-center justify-center">
                    {name ? name.charAt(0) : ""}
                  </p>{" "}
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
