import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { triggerNotification } from "../component/Toster";
import axios from "axios";
import { apiUrl } from '../services/baseUrl';

interface UserData {
  user_name: string;
  email_id: string;
  role_id: number;
}

const InviteUser = ({ isModalOpen, handleCancel, fetchData }: any) => {
  const [open, isOpen] = useState<boolean>(false);
  const [role, setRole] = useState([]);
  const [key, setKey] = useState<any>(0);

  // get fn Call
  useEffect(() => {
    fetchRoleData();
  }, []);

  // Get
  const fetchRoleData = async () => {
    try {
      const response = await axios.get(apiUrl + "getRoleList.php");
      // console.log("Role Data:", response.data);
      setRole(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  // Formik initial values and validation schema
  const initialValues = {
    email: "",
    role: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Please enter a valid email ID").required("Email ID is required"),
    role: Yup.string().required("Role is required"),
  });

  // const onSubmit = async (values: any, { setSubmitting }: any) => {
  //   const formdata = new FormData();
  //
  //   formdata.append("user_name", values.email);
  //   formdata.append("email_id", values.email);
  //   formdata.append("role_id", values.role);

  //   try {
  //     const response = await fetch("https://dev-api.gwscalculator.cloudnowtech.com/saveUser.php", {
  //       method: "POST",
  //       body: formdata,
  //     });
  //     const result = await response.text();
  //     console.log(result);

  //     triggerNotification("success", "Success", " User created successfully .", "topRight");
  //     handleCancel();
  //     fetchData();
  //   } catch (error) {
  //     console.error(error);
  //     triggerNotification("error", "Error", "Failed to create user", "topRight");
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    const formdata = new FormData();

    formdata.append("user_name", values.email);
    formdata.append("email_id", values.email);
    formdata.append("role_id", values.role);

    try {
      const response = await fetch(apiUrl + "saveUser.php", {
        method: "POST",
        body: formdata,
      });
      const result = await response.json(); // Assuming the response is JSON

      if (result.status === "error" && result.message === "Email ID already exists.") {
        triggerNotification("error", "Error", "Email Id already exists.", "topRight");
      } else {
        triggerNotification("success", "Success", "User created successfully.", "topRight");
        handleCancel();
        fetchData();
      }
    } catch (error) {
      console.error(error);
      triggerNotification("error", "Error", "Failed to create user", "topRight");
    } finally {
      setSubmitting(false);
    }
  };

  // Handle modal state and key change
  useEffect(() => {
    isOpen(isModalOpen);
    setKey(key + 1); // Force re-render of modal on state change
  }, [isModalOpen]);

  return (
    <Modal key={key} title="New User" className=" text-red-500" visible={open} onCancel={handleCancel} maskClosable={false} footer={null}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize={true}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <hr />
            <div className="grid grid-cols-1 gap-6 align-center mb-5 frm2">
              <div className="new-user">
                <label className="block mb-2 lab" htmlFor="email">
                  Email ID
                </label>
                <Field type="text" id="email" name="email" className="block w-full p-2.5" placeholder="" />
                <ErrorMessage name="email" component="div" className="text-red-500 text-[11px] xl:text-[15px]" />
              </div>
              <div className="relative invite-user">
                <label className="block mb-2  z-10 lab" htmlFor="role">
                  Role
                </label>

                <Field
                  as={Select}
                  showSearch
                  suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                  className="w-[100%] h-[42px] xl:h-[54px] cursor-role"
                  name={"role"}
                  value={values.role}
                  onChange={(value: any) => setFieldValue("role", value)}
                  filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  placeholder="Select"
                >
                  {role &&
                    role?.map((item: any) => (
                      <Select.Option key={item?.role_id} value={item?.role_id} className="letter-capital">
                        {item?.role_name}
                      </Select.Option>
                    ))}
                </Field>

                <ErrorMessage name="role" component="div" className="text-red-500 text-[11px] xl:text-[15px]" />
              </div>
            </div>
            <button type="submit" className="dow1" disabled={isSubmitting}>
              Add User
            </button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default InviteUser;
