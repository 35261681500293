import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#fff",
    backgroundColor: "#1F3B66",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 34,
    textAlign: "center",
    flexGrow: 1,
  },
  description: {
    width: "30%",
    color: "#fff",
    fontWeight: "medium",
    fontSize: 10,
  },
  qty: {
    width: "12%",
    color: "#fff",
    fontWeight: "medium",
    fontSize: 10,
  },
  rate: {
    width: "12%",
    color: "#fff",
    fontWeight: "medium",
    fontSize: 10,
  },
  amount: {
    width: "15%",
    color: "#fff",
    fontSize: 10,
  },
  igst: {
    width: "30%",
    color: "#fff",
    fontWeight: "medium",
    fontSize: 10,
  },
});

export default styles;
