import { notification } from "antd";
type NotificationType = "success" | "info" | "warning" | "error";
const triggerNotification = (
  type: NotificationType,
  message: string,
  description: string,
  placement: "topLeft" | "topRight" | "bottomLeft" | "bottomRight",
  className?: string, // New parameter for custom class name
  duration?: number,
) => {
  notification.destroy();
  notification[type]({
    message,
    description,
    placement,
    className, // Apply the custom class name
    duration,
  });
};
export { triggerNotification };
