import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Select, Table, TableProps } from "antd";
import { Button, Modal, Radio, Switch } from "antd";
import img1 from "../assets/delete.png";
import "../../src/index.css";
import "../../src/App.scss";
import img from "../assets/images/logo.png";
import { Formik, Field, FieldArray, ErrorMessage, FormikHelpers, FieldArrayRenderProps, Form } from "formik";
import { akkuFormPlanSchema, akkuFormProductSchema } from "./Schema/Schema";
import { useDispatch, useSelector } from 'react-redux';
import { setQuoteApprovals, getQuoteApprovals } from '../future/invoiceSlice';
import { ProductPricelist, setProductPriceDetails, capitalizeFirstLetter } from "./ProductPriceList";
import { Link } from "react-router-dom";
import { triggerNotification } from "../component/Toster";

export const subplans: any = {
    business: ["Starter", "Standard", "Plus"],
    enterprise: ["Starter", "Standard", "Plus"],
    frontline: ["Basic", "Plus"],
};

const optionPrices: any = {
    ssoIdp: 1500,
    amfa: 1500,
    passwordManager: 750,
    accessManager: 2500,
    cloudDirectory: 750
};
const basicPlan = 1500;
const professionalPlan = 3500;
const advancedPlan = 6500;

// Types
interface Product {
    productType: string;
    subPlan: string;
    licenses: number | "";
    customerGivenPrice: number | "";
}

interface FormValues {
    transationType: string;
    contractPeriod: string;
    customerCompanyName: string;
    customerName: string;
    email: string;
    customerDomain: string;
    products: Product[];
}

export const populateSubplans = (productType: any) => {
    return subplans[productType] || [];
};
const { Option } = Select;

const AkkuProductForm = (props: any) => {
    const [approvalModalOpen, setApprovalModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editModalOpen, setIsModalOpen1] = useState(false);
    const [delModalOpen, setIsModalOpen2] = useState(false);
    const [activeKeys, setActiveKeys] = useState<any>([]);
    const [visible, setVisible] = useState("0");

    const [isSendQuote, setIsSendQuote] = useState(false);
    const [findForm, setFindForm] = useState('');
    const [findFormFlag, setFindFormFlag] = useState<any>(null);

    const [akkuPriceFormInitValues, setAkkuPriceFormInitValues] = useState({
        transationType: "new_business",
        customerCompanyName: "",
        customerName: "",
        email: "",
        customerDomain: "",
        type: "plan",
        planType: "basic",
        optionsList: "",
        licenses: "",
        CustomerGivenPrice: "",
    });

    const [productPriceDetails1, setProductPriceDetails1] = useState<any>();
    const [productPriceDetails2, setProductPriceDetails2] = useState<any>();
    const [googleGivenPriceShow, setGoogleGivenPriceShow] = useState(false);
    const [isCalculated, setIsCalculated] = useState(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(0);
    const productFormRef = useRef<any>(null);
    const [isValid, setIsValid] = useState(false);
    const [priceVarifyPercentage, setPriceVarifyPercentage] = useState(11);
    const [allData, setAllData] = useState<any>(null);
    const [invoiceData, setInvoiceData] = useState<any>(null);
    const [rtoValue, setRtoValue] = useState('plan');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setQuoteApprovals(null));
    }, []);
    useEffect(() => {
        if (props.akkuFormUpdatedData) {
            if (sessionStorage.editInvoiceID) {
                setFormData(props.akkuFormUpdatedData);
            }
        }
    }, [props.akkuFormUpdatedData]);

    const setFormData = (data: any) => {
        const invoiceData: any = JSON.parse(data.invoice_data);
        if (invoiceData) {
            setRtoValue(invoiceData.type);
            setIsSendQuote(true);
            dispatch(setQuoteApprovals(null));
            setAkkuPriceFormInitValues({
                transationType: invoiceData.transationType,
                customerCompanyName: invoiceData.customerCompanyName,
                customerName: invoiceData.customerName,
                email: invoiceData.email,
                customerDomain: invoiceData.customerDomain,
                type: invoiceData.type,
                planType: invoiceData.planType,
                optionsList: invoiceData.optionsList ? invoiceData.optionsList : [],
                licenses: invoiceData.licenses,
                CustomerGivenPrice: invoiceData.CustomerGivenPrice
            });

            setTimeout(() => {
                document.getElementById("akkucalculateButton")?.click();
            }, 1000);
        }
    }
    const proposalSubmit = (values: any) => {

        console.log(values);
        debugger;
        if (values.type) {
            let totalCustomerAmt = values.licenses * values.CustomerGivenPrice;
            let planTotalAmt = 0;
            if (values.type == 'plan') {
                if (values.planType == 'basic') {
                    planTotalAmt = values.licenses * basicPlan;
                }
                if (values.planType == 'professional') {
                    planTotalAmt = values.licenses * professionalPlan;
                }
                if (values.planType == 'advanced') {
                    planTotalAmt = values.licenses * advancedPlan;
                }

                if (totalCustomerAmt >= planTotalAmt || isSendQuote) {
                    props.akkuProductFormData(values);
                } else {
                    approvalModalShowFromItemForm();
                }

            } else if (values.type === 'product') {
                let productTotalAmt = 0;

                // Loop through selected options and calculate the total amount
                values.optionsList.forEach((option: string) => {
                    if (optionPrices[option]) {
                        productTotalAmt += optionPrices[option] * values.licenses;
                    }
                });

                if (totalCustomerAmt >= productTotalAmt || isSendQuote) {
                    props.akkuProductFormData(values);
                } else {
                    approvalModalShowFromItemForm();
                }
            }
        }
    }
    const handleCustomerCompanyName = (value: any, setFieldValue: any) => {
        setFieldValue(`customerCompanyName`, value);
        hideAccordion();
    };
    const handleCustomerName = (value: any, setFieldValue: any) => {
        setFieldValue(`customerName`, value);
        hideAccordion();
    };
    const handlePlanType = (value: any, setFieldValue: any) => {
        setFieldValue(`planType`, value);
        hideAccordion();
    }
    const handleEmail = (value: any, setFieldValue: any) => {
        setFieldValue(`email`, value);
        hideAccordion();
    };
    const handleCustomerDomain = (value: any, setFieldValue: any) => {
        setFieldValue(`customerDomain`, value);
        hideAccordion();
    };
    const handleLicense = (value: any, setFieldValue: any) => {
        setFieldValue(`licenses`, value);
        hideAccordion();
    };
    const handleCustomerGivenPrice = (value: any, setFieldValue: any) => {
        setFieldValue(`CustomerGivenPrice`, value);
        hideAccordion();
    };
    const handleProductType = (value: any, setFieldValue: any) => {
        setFieldValue(`optionsList`, value);
        hideAccordion();
    };
    const hideAccordion = () => {
        setActiveKeys([]);
        setIsAccordionOpen(false);
        setIsSendQuote(false);
        dispatch(setQuoteApprovals(null));
        setFindForm('');
        props.hideAccordion();
    }
    const onChange = (checked: boolean) => {
        console.log(`switch to ${checked}`);
    };
    const rdoEvent = (value: any, setFieldValue: any) => {
        setFieldValue('type', value);
        setRtoValue(value);
        debugger;
    }
    const transationTypeEvent = (value: any, setFieldValue: any) => {
        setFieldValue('transationType', value);
    }
    const approvalModalShow = () => {
        setApprovalModalOpen(true);
    }
    const approvalModalShowFromItemForm = () => {
        setFindForm('approvalModalShowFromItemForm');
        setApprovalModalOpen(true);
    }
    const approvalModalCancel = () => {
        setApprovalModalOpen(false);
        setIsSendQuote(false);
        dispatch(setQuoteApprovals(null));
        setFindForm('');
    }
    const sendRequestQuote = () => {
        debugger;
        setIsSendQuote(true);
        setApprovalModalOpen(false);
        dispatch(setQuoteApprovals(true));
        if (findForm) {
            if (findForm == 'approvalModalShowFromItemForm') {
                // document.getElementById('addProductBtn')?.click();
                let numb = Math.round(Math.random() * (9999999 - 1111111) + 1111111);
                setFindFormFlag(numb);
                document.getElementById("akkucalculateButton")?.click();
            }
            if (findForm == 'mainForm') {
                document.getElementById("akkucalculateButton")?.click();
            }
        }
    }
    const cancelRequestQuote = () => {
        approvalModalCancel();
    }
    return (
        <>
            <Formik initialValues={akkuPriceFormInitValues}
                enableReinitialize={true}
                validationSchema={rtoValue == 'plan' ? akkuFormPlanSchema : akkuFormProductSchema}
                innerRef={productFormRef} onSubmit={proposalSubmit} >
                {({ isSubmitting, setFieldValue, values, handleSubmit, handleChange, errors, resetForm, isValid, touched }) => (
                    <>
                        <Form onSubmit={handleSubmit} name="outerform" id="outerform">
                            <h4>Transaction Details:</h4>
                            {/* <pre>{JSON.stringify(errors)}</pre>
                        <pre>{JSON.stringify(values, undefined, 2)}</pre> */}
                            <div className="grid grid-cols-2 gap-6 align-center mb-5 frm1">
                                <div className=" relative">
                                    <label className="block mb-2">Select Transaction Type</label>
                                    <select id="tran" className="p-2.5 w-full  cursor-pointer"
                                        name="transationType" value={values.transationType}
                                        onChange={(e: any) => transationTypeEvent(e.target.value, setFieldValue)}>
                                        <option value="new_business">New Business</option>
                                        <option value="renewal">Renewal</option>
                                    </select>
                                    <ErrorMessage name="transationType" component="div" className="error" />
                                </div>
                            </div>

                            <h4>Customer Details:</h4>
                            <div className="grid grid-cols-2 gap-6 align-center mb-5 frm1">
                                <div className="">
                                    <label className="block mb-2">Company Name</label>
                                    <input
                                        type="text"
                                        className="block w-full p-2.5 bg-[#F7F7F7]"
                                        placeholder=""
                                        name="customerCompanyName"
                                        value={values.customerCompanyName}
                                        // onChange={(e) => setFieldValue("customerCompanyName", e.target.value)}
                                        onChange={(e: any) => handleCustomerCompanyName(e.target.value, setFieldValue)}
                                    />
                                    <ErrorMessage name="customerCompanyName" component="div" className="error" />
                                </div>
                                <div className="">
                                    <label className="block mb-2">Customer Name</label>
                                    <input type="text" className="block w-full p-2.5" name="customerName"
                                        value={values.customerName}
                                        // onChange={(e) => setFieldValue("customerName", e.target.value)}
                                        onChange={(e: any) => handleCustomerName(e.target.value, setFieldValue)}
                                    />
                                    <ErrorMessage name="customerName" component="div" className="error" />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-6 align-center mb-5 frm1">
                                <div className="">
                                    <label className="block mb-2">Customer Email</label>
                                    <input type="text" id="" className="block w-full p-2.5" placeholder=""
                                        name="email" value={values.email}
                                        // onChange={(e) => setFieldValue("email", e.target.value)}
                                        onChange={(e: any) => handleEmail(e.target.value, setFieldValue)}
                                    />
                                    <ErrorMessage name="email" component="div" className="error" />
                                </div>
                                <div className="">
                                    <label className="block mb-2">Customer Domain</label>
                                    <input
                                        type="text"
                                        id=""
                                        className="block w-full p-2.5"
                                        placeholder=""
                                        name="customerDomain"
                                        value={values.customerDomain}
                                        // onChange={(e) => setFieldValue("customerDomain", e.target.value)}
                                        onChange={(e: any) => handleCustomerDomain(e.target.value, setFieldValue)}
                                    />
                                    <ErrorMessage name="customerDomain" component="div" className="error" />
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-6 align-center mb-5 frm1 rtoLable">
                                <div className="relative">
                                    <Radio.Group name="radiogroup" defaultValue={values.type}
                                        value={values.type}
                                        onChange={(e: any) => rdoEvent(e.target.value, setFieldValue)}>
                                        <Radio value={'plan'}><span className="radioOptionLbl">Plan</span></Radio>
                                        <Radio value={'product'}><span className="radioOptionLbl">Product</span></Radio>
                                    </Radio.Group>
                                </div>
                            </div>

                            {
                                values.type == 'plan' ? (
                                    <div className="grid grid-cols-1 gap-1 align-center mb-5 frm1">
                                        <div className=" relative">
                                            <label className="block mb-2">Plan Type</label>
                                            <select id="tran" className="p-2.5 w-full  cursor-pointer"
                                                name="planType" value={values.planType}
                                                onChange={(e: any) => handlePlanType(e.target.value, setFieldValue)}>
                                                <option value="basic">Basic</option>
                                                <option value="professional">Professional</option>
                                                <option value="advanced">Advanced</option>
                                            </select>
                                            <ErrorMessage name="planType" component="div" className="error" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="grid grid-cols-1 gap-1 align-center mb-5 frm1">
                                        <div className="relative">
                                            <label className="block mb-2">Product Type</label>
                                            <Select
                                                mode="multiple"
                                                placeholder="Select product types"
                                                className="p-2.5 w-full cursor-pointer"
                                                allowClear
                                                style={{ width: '100%' }}
                                                id="optionsList"
                                                value={values.optionsList ? values.optionsList : null} // Formik value
                                                // onChange={(value) => setFieldValue("optionsList", value)} // Capture changes in Formik
                                                onChange={(value: any) => handleProductType(value, setFieldValue)}
                                            >
                                                <Option value="ssoIdp">SSO/IDP</Option>
                                                <Option value="amfa">AMFA</Option>
                                                <Option value="passwordManager">Password Manager</Option>
                                                <Option value="accessManager">Access Manager</Option>
                                                <Option value="cloudDirectory">Cloud Directory</Option>
                                            </Select>
                                            <ErrorMessage name="optionsList" component="div" className="error" /> {/* Display validation error */}
                                        </div>
                                    </div>
                                )
                            }
                            <div className="grid grid-cols-2 gap-6 align-center mb-5 frm1">
                                <div className="">
                                    <label className="block mb-2">License</label>
                                    <input type="number" id="" className="block w-full p-2.5" placeholder=""
                                        name="licenses" value={values.licenses}
                                        onChange={(e: any) => handleLicense(e.target.value, setFieldValue)} />
                                    <ErrorMessage name="licenses" component="div" className="error" />
                                </div>
                                <div className="">
                                    <label className="block mb-2">Amount</label>
                                    <input type="number" id="" className="block w-full p-2.5"
                                        placeholder="" name="CustomerGivenPrice"
                                        onChange={(e: any) => handleCustomerGivenPrice(e.target.value, setFieldValue)}
                                        value={values.CustomerGivenPrice} />
                                    <ErrorMessage name="CustomerGivenPrice" component="div" className="error" />
                                </div>
                            </div>

                            <div className="flex justify-center mt-7">
                                <button type="submit"
                                    className="n-user flex align-center"
                                    id="akkucalculateButton"
                                    name="akkucalculateButton">
                                    Calculate Price
                                </button>
                            </div>

                        </Form>
                    </>
                )}
            </Formik >

            <Modal title="" open={approvalModalOpen} onCancel={approvalModalCancel} footer={false}>
                <div className="grid grid-cols-1 gap-6 align-center mb-0 xl:mb-5 frm1">
                    <div className="text-center">
                        {/* <img className="m-auto w-auto" src={img1} alt="Your Company" /> */}

                        <h2 className="pt-2 text-[22px] xl:text-[28px]">Request send quote</h2>
                        <p className="pt-4 text-[15px] xl:text-[18px]">Contact Dinesh to get approvals for this price?</p>
                    </div>
                    <div className=""></div>
                </div>
                <div className="flx">
                    <button type="button" onClick={sendRequestQuote} className="dow1">
                        Send Request
                    </button>
                    <button type="button" onClick={cancelRequestQuote} className="dow1">
                        Cancel Request
                    </button>
                </div>
            </Modal>
        </>
    )
}

export default AkkuProductForm;