import * as Yup from "yup";
// Define a schema for each product item
// const productSchema = Yup.object().shape({
//   productType: Yup.string().required("Product Type is required"),
//   subPlan: Yup.string().required("Subplan is required"),
//   licenses: Yup.number().required("Licenses are required"),
//   CustomerGivenPrice: Yup.number().required("Licenses are required"),
//   googlePrice: Yup.number()
//   // CustomerGivenPrice: Yup.string()
//   //   .nullable()
//   //   .transform((value, originalValue) => (typeof originalValue === "string" && originalValue.trim() === "" ? null : value))
//   //   .required("Customer Given Price is required"),
//   // googlePrice: Yup.lazy((value: any, context: any) => {
//   //   console.log(context);
//   //   let tempPriceGivenByGoogle = context.priceGivenByGoogle; // Access the value of priceGivenByGoogle
//   //   return tempPriceGivenByGoogle == true ? Yup.number().required("Google Price is required") : Yup.number().nullable().default(null);
//   // }),
// });
// // Define the main form schema
// export const gwsPriceFormSchema = Yup.object().shape({
//   transationType: Yup.string().required("Transaction Type is required"),
//   contractPeriod: Yup.string().required("Contract Period is required"),
//   priceGivenByGoogle: Yup.boolean().default(false).required("Price Given By Google is required"),
//   customerCompanyName: Yup.string().required("Company Name is required"),
//   customerName: Yup.string().required("Customer Name is required"),
//   email: Yup.string().email("Invalid Email address").required("Email Id is required"),
//   customerDomain: Yup.string()
//     .matches(/^(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/, "Invalid domain format")
//     .required("Domain Name is required"),
//   products: Yup.array().of(productSchema),
//   // products: Yup.array()
//   //   .of(productSchema)
//   //   .test("at-least-one-product", "At least one product is required", function (value: any) {
//   //     // Check if at least one product has all required fields filled out
//   //     return value.some((product: any) => product.productType && product.subPlan);
//   //   }),
// });

export const gwsPriceFormSchema = Yup.object().shape({
  transationType: Yup.string().required("Transaction type is required"),
  contractPeriod: Yup.string().required("Contract period is required"),
  priceGivenByGoogle: Yup.boolean().default(false).required("Price given by Google is required"),
  customerCompanyName: Yup.string().required("Company Name is required"),
  customerName: Yup.string().required("Customer Name is required"),
  email: Yup.string().email("Invalid Email Id").required("Customer Email is required"),
  customerDomain: Yup.string()
    .matches(/^(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/, "Invalid domain format")
    .required("Customer Domain is required"),
  products: Yup.array().of(
    Yup.object().shape({
      productType: Yup.string().required('Plan is required'),
      subPlan: Yup.string().required('SKU is required'),
      licenses: Yup.number().required('Licenses is required'),
      googlePrice: Yup.number().required('Google price is required'),
      CustomerGivenPrice: Yup.number().required('Customer price is required')
    })
  ).min(1, 'At least one product details is required')
});

export const akkuFormPlanSchema = Yup.object().shape({
  transationType: Yup.string().required("Transaction type is required"),
  customerCompanyName: Yup.string().required("Company Name is required"),
  customerName: Yup.string().required("Customer Name is required"),
  email: Yup.string().email("Invalid Email Id").required("Customer Email is required"),
  customerDomain: Yup.string()
    .matches(/^(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/, "Invalid domain format")
    .required("Customer Domain is required"),
  type: Yup.string().required("Type name is required"),
  planType: Yup.string().required('Plan type is required'),
  licenses: Yup.number().required('Licenses is required'),
  CustomerGivenPrice: Yup.number().required('Customer price is required')
});

export const akkuFormProductSchema = Yup.object().shape({
  transationType: Yup.string().required("Transaction type is required"),
  customerCompanyName: Yup.string().required("Company Name is required"),
  customerName: Yup.string().required("Customer Name is required"),
  email: Yup.string().email("Invalid Email Id").required("Customer Email is required"),
  customerDomain: Yup.string()
    .matches(/^(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/, "Invalid domain format")
    .required("Customer Domain is required"),
  type: Yup.string().required("Type name is required"),
  planType: Yup.string(),
  optionsList: Yup.array().of(Yup.string()).required("Select at least one option"),
  licenses: Yup.number().required('Licenses are required'),
  CustomerGivenPrice: Yup.number().required('Customer price is required')
});
