import loader from "../assets/loader1.gif";

const FullPageLoader = () => {
  return (
    <div data-testid="loader" className="loader left-0 absolute w-full h-[100%] flex justify-center items-center  z-[999] bg-[#EFEDFC] rounded-lg">
      <img className="loader-akku" src={loader} alt="loader" />
    </div>
  );
};

export default FullPageLoader;
