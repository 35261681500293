import React, { useEffect, useState } from "react";
import { Select, Table, TableProps } from "antd";
import { Button, Modal } from "antd";
import img1 from "../assets/delete.png";
import Header from "../component/Header";
import { Link } from "react-router-dom";
import userEdit from "../assets/userEdit.png";
import userDelete from "../assets/userDelete.png";
import InviteUser from "./InviteUser";
import { triggerNotification } from "../component/Toster";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import axios from "axios";
import { values } from "lodash";
import generateTitle from "./generateTitle";
import { SortOrder } from "antd/es/table/interface";
import Loader from "./Loader";
import { debug } from "console";
import { apiUrl } from '../services/baseUrl';

const UserManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModalOpen, setIsModalOpen1] = useState(false);
  const [delModalOpen, setIsModalOpen2] = useState(false);
  const [role, setRole] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [loader, setLoader] = useState(false);
  const [editingUser, setEditingUser] = useState<any>({
    email: "",
    role: "",
  });
  const [deleteUserId, setDeleteUserId] = useState<any>(null);
  //yup validation schema
  const validationSchema = Yup.object({
    email: Yup.string().email("Please enter a valid email ID").required(" Email ID is required"),
    role: Yup.string().required(" Role is required"),
  });
  const [selectedEditUserId, setSelectedEditUserId] = useState<any>(null);

  //user name
  function getLocalPart(email: string): string {
    const atIndex = email.indexOf("@");
    if (atIndex === -1) {
      throw new Error("Invalid email address");
    }
    return email.substring(0, atIndex);
  }

  useEffect(() => {
    fetchData();
    fetchRoleData();
  }, []);

  // Get Role
  const fetchRoleData = async () => {
    try {
      const response = await axios.get(apiUrl + "getRoleList.php");
      // console.log("role Data:", response.data);
      setRole(response.data);
    } catch (error) {
      console.error("error fetching:", error);
    }
  };

  // Get user list
  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await axios.get(apiUrl + "/getUserList.php");
      // console.log("User lIst :", response.data);
      const tempArray: any = response?.data?.users?.map((item: any, index: any) => ({
        key: index + 1,
        user_id: item?.user_id,
        name: item?.user_name,
        role: item?.role_name,
        role_id: item?.role_id,
        email: item?.email_id,
      }));
      // console.log("check", tempArray);

      setLoader(false);
      setUserList(tempArray);
      setUserData(tempArray);
    } catch (error) {
      setLoader(false);
      console.error("error fetching:", error);
    }
  };

  //edit call
  const handleEdit = (record: any) => {
    setSelectedEditUserId(record.user_id);
    let val = {
      email: record.email,
      role: record.role_id,
    };
    setEditingUser(val);
    setIsModalOpen1(true);
    fetchData();
  };

  const handleDelete = (record: any) => {
    // console.log(record, "deleeee");
    setDeleteUserId(record.user_id);
    setIsModalOpen2(true);
  };

  const onDelete = async () => {
    try {
      const formdata = new FormData();
      formdata.append("user_id", deleteUserId);

      const response = await fetch(apiUrl + "deleteUser.php", {
        method: "POST",
        body: formdata,
      });

      const result = await response.json();
      console.log(result);

      if (result.status === "success") {
        triggerNotification("success", "Success", "User deleted successfully", "topRight");
        handleCancel();
        fetchData();
      } else {
        triggerNotification("error", "Alert", "This user cannot be deleted as they are associated with invoices.", "topRight");
      }
    } catch (error: any) {
      debugger;
      console.error(error);
      triggerNotification("error", "Error", "Please try again or contact admin" || "Failed to delete user", "topRight");
    }
  };

  const getRoleId = (roleName: string) => {
    const selectedRole: any = role.find((r: any) => r.role_name === roleName);
    return selectedRole ? selectedRole.role_id : null;
  };

  const onEdit = async (values: any, { setSubmitting }: any) => {
    try {
      const formdata = new FormData();

      // console.log("Form Values:", values); // Log form values for debugging
      formdata.append("user_name", values.email); // Ensure the correct key
      formdata.append("email_id", values.email); // Ensure the correct key
      formdata.append("role_id", values.role); // Ensure the correct key
      formdata.append("user_id", selectedEditUserId); // Ensure the correct key

      const response = await fetch(apiUrl + "editUser.php", {
        method: "POST",
        body: formdata,
      });
      const result = await response.json(); // Parse JSON response
      // console.log("API Response:", result);

      if (result.status === "success") {
        triggerNotification("success", "Success", "User details updated successfully", "topRight");
        handleCancel();
        fetchData();
      } else {
        triggerNotification("error", "Error", "Please try again or contact admin" || "Failed to update user details", "topRight");
      }
    } catch (error) {
      console.error(error);
      triggerNotification("error", "Error", "Failed to update user details", "topRight");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSearch = (event: any) => {
    const searchEvent = event?.target?.value;
    setSearchValue(searchEvent.toLowerCase());
    filterSearch(searchEvent.toLowerCase());
  };

  //Search

  const filterSearch = (searchEvent: any) => {
    const searchResult = userData.filter((item: any) => {
      const lowerCaseSearchEvent = searchEvent.toLowerCase();
      return (
        item?.name?.toString().toLowerCase().includes(lowerCaseSearchEvent) ||
        item?.role?.toString().toLowerCase().includes(lowerCaseSearchEvent) ||
        item?.email?.toString().toLowerCase().includes(lowerCaseSearchEvent)
      );
    });
    setUserList(searchResult);
  };

  const handleSubmit = () => {
    triggerNotification("error", "", "User details deleted", "topRight");
    handleCancel();
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalOpen1(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpen1(false);
    setIsModalOpen2(false);
  };

  const columns = [
    // {
    //   title: "S.no",
    //   dataIndex: "key",
    //   key: "key",
    //   width: "10%",
    // },

    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "S.No",
          sortColumns?.find(({ column }: any) => column.key === "key"),
        ),
      dataIndex: "key",
      key: "key",
      defaultSortOrder: "ascend" as SortOrder,
      width: "10%",
      sorter: (a: any, b: any) => a?.key - b?.key, // Numerical comparison
      render: (text: any, record: any) => <p className=""> {text}</p>,
    },
    // {
    //   title: "User Name",
    //   dataIndex: "name",
    //   key: "name",
    //   width: "25%",
    //   render: (text: any, record: any) => <p className="letter-capital"> {text}</p>,
    // },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "User Name",
          sortColumns?.find(({ column }: any) => column.key === "name"),
        ),
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => <p className="letter-capital"> {getLocalPart(text)}</p>,
      defaultSortOrder: "ascend" as SortOrder,
      width: "27%",
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
    },
    // {
    //   title: "Role",
    //   dataIndex: "role",
    //   key: "role",
    //   width: "20%",
    //   render: (text: any, record: any) => <p className="letter-capital"> {text}</p>,
    // },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Role",
          sortColumns?.find(({ column }: any) => column.key === "role"),
        ),
      dataIndex: "role",
      key: "role",
      render: (text: any, record: any) => <p className="letter-capital"> {text}</p>,
      defaultSortOrder: "ascend" as SortOrder,
      width: "24%",
      sorter: (a: any, b: any) => a?.role?.localeCompare(b?.role),
    },

    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Email ID",
          sortColumns?.find(({ column }: any) => column.key === "email"),
        ),
      dataIndex: "email",
      key: "email",
      defaultSortOrder: "ascend" as SortOrder,
      width: "27%",
      sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
    },

    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      width: "9%",
      render: (text: any, record: any) => (
        <div className="flex ">
          <p className={`${text?.toLowerCase()} name editk cursor-pointer font-Inter text-[16px] text-[#3A3A3A]  `} onClick={() => handleEdit(record)}>
            <img src={userEdit} alt="" className="w-[30px] h-auto" />
          </p>
          <p className={`${text?.toLowerCase()} name cursor-pointer font-Inter text-[16px] text-[#3A3A3A] pl-[2px]`} onClick={() => handleDelete(record)}>
            <img src={userDelete} alt="" className="w-[30px] h-auto" />
          </p>
        </div>
      ),
    },
  ];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      role: "admin",
      date: "12-12-2024",
    },
    {
      key: "2",
      name: "John",
      role: "admin",
      date: "05-12-2024",
    },
  ];

  return (
    <div>
      <Header />
      <div className="  mx-auto   w-[95%] xl:w-[97%] 2xl:w-[95%] mt-[96px]		xl:mt-[120px]">
        <button className="flex bck  ">
          {" "}
          <Link to="/invoice_logs" className=" flex  ">
            <span className="material-symbols-outlined pr-1 pt-0 xl:pt-[2px] text-[20px] xl:text-[24px]">keyboard_backspace</span>Back
          </Link>
        </button>
        <div className="bg-gy pt-3">
          <div className="flex justify-between gap-6 align-center mb-5">
            <div className="...">
              <h3>User Management</h3>
            </div>
            <div className="...">
              <div className="al-ser1">
                <div>
                  <div className="flex px-2 py-2 rounded-md border-2 int w-full	 overflow-hidden  font-[sans-serif]">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className=" w-[14px] xl:w-[24px] h-[18px] xl:h-[24px]" xmlns="http://www.w3.org/2000/svg">
                      <g id="vuesax/linear/search-normal">
                        <g id="search-normal">
                          <path
                            id="Vector"
                            d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                            stroke="#292D32"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path id="Vector_2" d="M22 22L20 20" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                      </g>
                    </svg>

                    <input type="email" placeholder="Search " onChange={handleSearch} value={searchValue} className="w-full outline-none bg-transparent text-gray-600 text-[11px] xl:text-[14px]" />
                  </div>
                </div>
                <div>
                  {" "}
                  <button className="n-user flex align-center text-[14px]" type="button" onClick={showModal}>
                    <span className="material-symbols-outlined text-[21px] xl:text-[24px]">add</span> Add User
                  </button>
                </div>

                <Modal title="Edit User" open={editModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
                  <Formik initialValues={editingUser} validationSchema={validationSchema} onSubmit={onEdit} enableReinitialize={true}>
                    {({ isSubmitting, values, setFieldValue }) => (
                      <Form>
                        <hr />
                        <div className="grid grid-cols-1 gap-6 align-center mb-5 frm2">
                          <div className="new-user">
                            <label className="block mb-2 lab" htmlFor="email">
                              Email ID
                            </label>
                            <Field type="text" id="email" name="email" className="block w-full p-2.5" placeholder="" value={values.email} />
                            <ErrorMessage name="email" component="div" className="text-red-500" />
                          </div>
                          <div className="relative invite-user">
                            <label className="block mb-2  z-10 lab" htmlFor="role">
                              Role
                            </label>

                            <Field
                              as={Select}
                              showSearch
                              suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                              className="w-[100%] h-[42px] xl:h-[54px]"
                              name={"role"}
                              value={values.role}
                              onChange={(value: any) => setFieldValue("role", value)}
                              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              placeholder="Select"
                            >
                              {role &&
                                role?.map((item: any) => (
                                  <Select.Option key={item?.role_id} value={item.role_id}>
                                    {item?.role_name}
                                  </Select.Option>
                                ))}
                              {/* <Select.Option value="user">User</Select.Option> */}
                            </Field>
                            <ErrorMessage name="role" component="div" className="text-red-500" />
                          </div>
                        </div>
                        <button type="submit" className="dow1" disabled={isSubmitting}>
                          Save
                        </button>
                      </Form>
                    )}
                  </Formik>
                </Modal>

                <Modal title="" open={delModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
                  <div className="grid grid-cols-1 gap-6 align-center mb-0 xl:mb-5 frm1">
                    <div className="text-center">
                      <img className="m-auto w-auto" src={img1} alt="Your Company" />

                      <h2 className="pt-2 text-[22px] xl:text-[28px]">Delete</h2>
                      <p className="pt-4 text-[15px] xl:text-[18px]">Are you sure you want to delete this user?</p>
                    </div>
                    <div className=""></div>
                  </div>
                  <div className="flx">
                    <button type="button" onClick={handleCancel} className="n-user3">
                      Cancel
                    </button>
                    <button type="button" onClick={onDelete} className="dow1">
                      Delete
                    </button>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          {/* {loader && <Loader />} */}
          <Table
            columns={columns}
            dataSource={userList}
            loading={loader}
            // pagination={{
            //   showSizeChanger: true,
            // }}
            pagination={{ pageSize: 20 }}
          />
        </div>
      </div>
      <InviteUser handleCancel={handleCancel} isModalOpen={isModalOpen} fetchData={fetchData} />
    </div>
  );
};

export default UserManagement;
