import React, { Fragment } from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { spiltRupees } from "./formConfig"; // import configurations
import rupee from "../assets/rupee-symp.png";

const borderColor = "#fff";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#dddddd",
    borderBottomWidth: 2,
    alignItems: "center",
    height: 45,
  },
  description: {
    paddingTop: 5,
    paddingBottom: 5,
    width: "33%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    fontSize: 8,
    // fontFamily:"Inter",
    fontWeight: "medium",
    color: "#1F3066",
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 9,
    fontSize: 8.1,
    // fontFamily:"Inter",
    fontWeight: "medium",
    color: "#1F3066",
  },
  rateCalculations: {
    width: "12%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  rate: {
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
    fontSize: 8.1,
    // fontFamily:"Inter",
    fontWeight: "medium",
    color: "#1F3066",
  },
  amountCalculations: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "15%",
  },
  amount: {
    textAlign: "center",
    // paddingRight: 8,
    fontSize: 8.1,
    // fontFamily:"Inter",
    fontWeight: "medium",
    color: "#1F3066",
  },
  CGSTCalculations: {
    width: "15%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  CGST: {
    textAlign: "center",
    paddingRight: 8,
    fontSize: 8.1,
    // fontFamily:"Inter",
    fontWeight: "medium",
    color: "#1F3066",
  },
  IGSTCalculations: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  IGST: {
    textAlign: "center",
    paddingRight: 8,
    fontSize: 8.1,
    // fontFamily:"Inter",
    fontWeight: "medium",
    color: "#1F3066",
  },
  highlight: {
    color: "#727272",
    fontSize: 7.8,
    marginTop: 2,
  },
  desc: {
    fontSize: 8.5,
    // fontFamily:"Inter",
    fontWeight: "medium",
    color: "#1F3066",
  },

  rupeeLog: {
    height: "8px",
    width: "8px",
    fontWeight: "medium",
    fontSize: 8,
  },
});

const InvoiceTableRow = (props) => {

  const rows = props.pdfData.productsPriceList.map((item) => (
    <View style={styles.row} key={item.sno.toString()}>
      {/* <Text style={styles.description}>
                {item.desc}
                <Text style={styles.highlight}>( Annual Plan-Yearly Billing ) - "{initialValues.customerDomain}"</Text>
            </Text> */}
      <View style={styles.description}>
        <Text style={styles.desc}>{item.desc}</Text>
        <Text style={styles.highlight}>(Annual Plan-Yearly Billing) - "{props.pdfData.customerDomain}" </Text>
      </View>
      <Text style={styles.qty}> {spiltRupees(item.qty)}</Text>
      <View style={styles.rateCalculations}>
        <Image style={styles.rupeeLog} src={rupee} />
        <Text style={styles.rate}> {spiltRupees(item.rate)}</Text>
      </View>
      {props.pdfData.billto.state == "33" ? (
        <>
          <View style={styles.CGSTCalculations}>
            <Image style={styles.rupeeLog} src={rupee} />
            <Text style={styles.CGST}>
              {spiltRupees(item.CGST)} <Text style={styles.highlight}>(9%)</Text>
            </Text>
          </View>
          <View style={styles.CGSTCalculations}>
            <Image style={styles.rupeeLog} src={rupee} />
            <Text style={styles.CGST}>
              {spiltRupees(item.SGST)} <Text style={styles.highlight}>(9%)</Text>
            </Text>
          </View>
        </>
      ) : (
        <View style={styles.IGSTCalculations}>
          <Image style={styles.rupeeLog} src={rupee} />
          <Text style={styles.IGST}>
            {spiltRupees(item.IGST)} <Text style={styles.highlight}>(18%)</Text>
          </Text>
        </View>
      )}
      <View style={styles.amountCalculations}>
        <Image style={styles.rupeeLog} src={rupee} />
        <Text style={styles.amount}>{spiltRupees(item.amount.toFixed(2))}</Text>
      </View>
    </View>
  ));

  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
