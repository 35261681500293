import * as Yup from "yup";
import dayjs from "dayjs";

const dateFormat = "DD-MM-YYYY";

export const options = [
    { value: '', label: 'Select a state' },
    { value: '35', label: 'Andaman And Nicobar Islands' },
    { value: '28', label: 'Andhra Pradesh (Before Division)' },
    { value: '37', label: 'Andhra Pradesh (Newly Added)' },
    { value: '12', label: 'Arunachal Pradesh' },
    { value: '18', label: 'Assam' },
    { value: '10', label: 'Bihar' },
    { value: '4', label: 'Chandigarh' },
    { value: '22', label: 'Chattisgarh' },
    { value: '99', label: 'Centre Jurisdiction' },
    { value: '26', label: 'Dadra And Nagar Haveli And Daman And Diu' },
    { value: '7', label: 'Delhi' },
    { value: '30', label: 'Goa' },
    { value: '24', label: 'Gujarat' },
    { value: '6', label: 'Haryana' },
    { value: '2', label: 'Himachal Pradesh' },
    { value: '1', label: 'Jammu And Kashmir' },
    { value: '20', label: 'Jharkhand' },
    { value: '29', label: 'Karnataka' },
    { value: '32', label: 'Kerala' },
    { value: '38', label: 'Ladakh' },
    { value: '31', label: 'Lakshadweep' },
    { value: '23', label: 'Madhya Pradesh' },
    { value: '27', label: 'Maharashtra' },
    { value: '14', label: 'Manipur' },
    { value: '17', label: 'Meghalaya' },
    { value: '15', label: 'Mizoram' },
    { value: '13', label: 'Nagaland' },
    { value: '21', label: 'Odisha' },
    { value: '97', label: 'Other Territory' },
    { value: '34', label: 'Puducherry' },
    { value: '3', label: 'Punjab' },
    { value: '8', label: 'Rajasthan' },
    { value: '11', label: 'Sikkim' },
    { value: '33', label: 'Tamil Nadu' },
    { value: '36', label: 'Telangana' },
    { value: '16', label: 'Tripura' },
    { value: '5', label: 'Uttarakhand' },
    { value: '9', label: 'Uttar Pradesh' },
    { value: '19', label: 'West Bengal' },
];
export const validationSchema = Yup.object().shape({
    address: Yup.string().required("Address is required"),
    mobileNumber: Yup.string()
        .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
        .required("Mobile Number is required"),
    gstinNumber: Yup.string()
        .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
            "Please enter a valid GSTIN number"
        )
        .nullable()
        .notRequired(),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State/Region is required"),
    zipcode: Yup.string()
        .matches(/^\d{6}$/, "Please enter a valid 6-digit zipcode")
        .required("Zipcode is required"),
    // expiryDate: Yup.date().required("Expiry Date is required"),
    expiryDate: Yup.date()
        .transform(function (value, originalValue) {
            if (this.isType(value)) return value;
            const parsedDate: any = parseDate(originalValue);
            return isNaN(parsedDate) ? new Date('') : parsedDate;
        })
        .typeError('expiryDate must be a valid date'),
    terms: Yup.string().required("Terms & Conditions are required"),
    city: Yup.string().required("City is required")
});
const parseDate = (dateString: any) => {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
};
export const findCGST_SGST = (totalValue: any) => {
    const percentage = 9;
    const CGST_SGST = (percentage / 100) * totalValue;
    const formatValue = CGST_SGST.toFixed(2);
    return convertToNumber(formatValue);
}
export const convertToNumber = (str: any) => {
    const number = parseInt(str);
    return !isNaN(number) ? number : 0;
}
export const findIGST = (totalValue: any) => {
    const percentage = 18;
    const CGST_SGST = (percentage / 100) * totalValue;
    const formatValue = CGST_SGST.toFixed(2);
    return convertToNumber(formatValue);
}
export const capitalized = (string: any) => {
    if (!string) return "";
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalized;
}
export const spiltRupees = (val: any) => {
    const number = parseFloat(val);
    if (isNaN(number)) {
        return 0;
    } else {
        return number.toLocaleString("en-IN", { maximumFractionDigits: 2 });
    }
};
const stateName: { [key: string]: number } = {
    "JAMMU AND KASHMIR": 1,
    "HIMACHAL PRADESH": 2,
    "PUNJAB": 3,
    "CHANDIGARH": 4,
    "UTTARAKHAND": 5,
    "HARYANA": 6,
    "DELHI": 7,
    "RAJASTHAN": 8,
    "UTTAR PRADESH": 9,
    "BIHAR": 10,
    "SIKKIM": 11,
    "ARUNACHAL PRADESH": 12,
    "NAGALAND": 13,
    "MANIPUR": 14,
    "MIZORAM": 15,
    "TRIPURA": 16,
    "MEGHALAYA": 17,
    "ASSAM": 18,
    "WEST BENGAL": 19,
    "JHARKHAND": 20,
    "ODISHA": 21,
    "CHATTISGARH": 22,
    "MADHYA PRADESH": 23,
    "GUJARAT": 24,
    "DADRA AND NAGAR HAVELI AND DAMAN AND DIU": 26,
    "MAHARASHTRA": 27,
    "ANDHRA PRADESH (BEFORE DIVISION)": 28,
    "KARNATAKA": 29,
    "GOA": 30,
    "LAKSHADWEEP": 31,
    "KERALA": 32,
    "TAMIL NADU": 33,
    "PUDUCHERRY": 34,
    "ANDAMAN AND NICOBAR ISLANDS": 35,
    "TELANGANA": 36,
    "ANDHRA PRADESH (NEWLY ADDED)": 37,
    "LADAKH": 38,
    "OTHER TERRITORY": 97,
    "CENTRE JURISDICTION": 99
};

// Function to capitalize the first letter of each word in a string
const capitalizeWords = (str: string): string => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
};

// Function to get the state name based on the state code
export const getStateName = (code: number): string => {
    const stateCodeMap: { [key: number]: string } = Object.entries(stateName).reduce((acc, [key, value]) => {
        acc[value] = key;
        return acc;
    }, {} as { [key: number]: string });

    return capitalizeWords(stateCodeMap[code]) || "Unknown State Code";
};

// // Example usage
// const stateCode: number = 27;
// const state: string = getStateName(stateCode);
// console.log(`The state with code ${stateCode} is ${state}.`); // Output: The state with code 27 is MAHARASHTRA.
