import React from 'react'
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableFooter from './InvoiceTableFooter';
import styles from './AkkuInvoiceItemsTableStyles'; // Import styles
import { stateCodes, normalizeStateName, getStateCode } from './constant'; // Import styles

const AkkuInvoiceItemsTable = (props) => {

    return (
        <View style={styles.tableContainer}>
            <View style={styles.container}>
                <Text style={styles.description}>Item Description</Text>
                <Text style={styles.qty}>Qty</Text>
                <Text style={styles.rate}>Rate</Text>
                {
                    props.pdfData.billto.state == "33" ? (
                        <>
                            <Text style={styles.amount}>CGST</Text>
                            <Text style={styles.amount}>SGST</Text>
                        </>
                    ) : (
                        <Text style={styles.igst}>IGST</Text>
                    )
                }
                <Text style={styles.amount}>Amount</Text>
            </View>
            <InvoiceTableRow {...props} />
            <InvoiceTableFooter {...props} />
            {/* <AkkuInvoiceTableRow items={invoice.items} initialValues={initialValues}/>
            <AkkuInvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
            <AkkuInvoiceTableFooter items={invoice.items} priceDetails={priceDetails} /> */}
        </View>
    )
}

export default AkkuInvoiceItemsTable