import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { Button, DatePicker, Input, Select } from "antd";
import pdf from "../assets/pdf .png";
import share from "../assets/share .png";
import moment from "moment";
import PdfView from "./PdfView";
import dayjs from "dayjs";
import { options, validationSchema, findCGST_SGST, findIGST, capitalized, getStateName } from "./formConfig"; // import configurations
import { ToWords } from "to-words";
import { triggerNotification } from "../component/Toster";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setQuoteApprovals, getQuoteApprovals } from '../future/invoiceSlice';
import { doc } from "prettier";
import { apiUrl } from '../services/baseUrl';

// Define the mapping object
const productNameMapping: { [key: string]: string } = {
  ssoIdp: "SSO / IDP",
  amfa: "AMFA",
  passwordManager: "Password Manager",
  accessManager: "Access Manager",
  cloudDirectory: "Cloud Directory",
  // Add other options if needed
};

const BillingDetailForms = (props: any) => {
  const { invoiceData } = props;
  const [allFormData, setAllFormData] = useState<any>(null);
  const dateFormat = "DD-MM-YYYY";
  const toWords = new ToWords();
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let apprveStatus: any = '0';

  const invoiceApproveStatus = useSelector((state: any) => {
    return state.invoice;
  });

  const [initialValues, setInitialValues] = useState({
    address: "",
    mobileNumber: "",
    gstinNumber: "",
    country: "India",
    state: "33",
    zipcode: "",
    expiryDate: dayjs().format(dateFormat),
    terms: "",
    city: ""
  });

  useEffect(() => {
    init();
  }, [props.invoiceData]);

  const init = () => {
    if (invoiceData) {
      let billtoData = JSON.parse(invoiceData.invoice_data).billto;

      if (billtoData) {
        setInitialValues({
          address: billtoData.address,
          mobileNumber: billtoData.mobileNumber,
          gstinNumber: billtoData.gstinNumber,
          country: billtoData.country,
          state: billtoData.state,
          zipcode: billtoData.zipcode,
          expiryDate: billtoData.expiryDate,
          terms: billtoData.terms,
          city: billtoData.city
        });
      }
    }
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    let dateVal = dayjs(values.expiryDate).format(dateFormat) == "Invalid Date" ? values.expiryDate : dayjs(values.expiryDate).format(dateFormat);
    let pdfData = {
      ...props.allData,
      billto: {
        ...values,
        stateName: getStateName(values.state),
        expiryDate: dateVal,
      },
    };
    setSubmitting(false);

    if (pdfData) {
      let totals = {
        subTotal: 0,
        total_CGST: 0,
        total_SGST: 0,
        total_IGST: 0,
        Total: 0,
        words: "",
      };

      if (props.rtoValue == 'Akku') {

        let givenPrice = Number(props.allData.CustomerGivenPrice);
        let numberOfLicenses = Number(props.allData.licenses);
        let totalAmt = numberOfLicenses * givenPrice;
        totals.subTotal = totalAmt;
        debugger;
        const planType = props.allData.type.toLowerCase();

        const desc = planType === 'product'
          ? `Akku - Product\n(${props.allData.optionsList.map((option: any) => {
            const displayName = productNameMapping[option] || option; // Use the mapping or fallback to the option itself
            return displayName;
          }).join(', ')})`
          : `Akku - ${capitalized(props.allData.planType)}`;

        let productsPriceList = [{
          sno: 1,
          planPrice: props.allData.CustomerGivenPrice,
          licenses: props.allData.licenses,
          productType: props.allData.transationType,
          planType: props.allData.planType,
          subplan: props.allData.type,
          CGST: findCGST_SGST(totalAmt),
          SGST: findCGST_SGST(totalAmt),
          IGST: findIGST(totalAmt),
          amount: totalAmt,
          desc: desc,
          qty: props.allData.licenses,
          rate: props.allData.CustomerGivenPrice
        }];
        debugger;
        if (productsPriceList) {
          totals.total_CGST = findCGST_SGST(totals.subTotal);
          totals.total_SGST = findCGST_SGST(totals.subTotal);
          totals.total_IGST = findIGST(totals.subTotal);
          totals.Total = totals.total_IGST + totals.subTotal;
          totals.words = toWords.convert(totals.Total);

          pdfData.productsPriceList = productsPriceList;
          pdfData.totals = totals;

          if (pdfData.productsPriceList && pdfData.totals) {
            setAllFormData(pdfData);
            handlePopUp(props, pdfData);
          }
        }
        debugger;

      } else {
        let productsPriceList = props.allData.products.map((object: any, index: any) => {
          let givenPrice = Number(object.CustomerGivenPrice);
          let numberOfLicenses = Number(object.licenses);
          let totalAmt = numberOfLicenses * givenPrice;
          totals.subTotal += totalAmt;

          return {
            sno: index + 1,
            planPrice: givenPrice,
            licenses: numberOfLicenses,
            productType: object.productType,
            subplan: object.subplan,
            CGST: findCGST_SGST(totalAmt),
            SGST: findCGST_SGST(totalAmt),
            IGST: findIGST(totalAmt),
            amount: totalAmt,
            desc: `Google Workspace ${capitalized(object.productType)} ${capitalized(object.subplan)}`,
            qty: numberOfLicenses,
            rate: object.CustomerGivenPrice,
          };
        });

        if (productsPriceList) {
          totals.total_CGST = findCGST_SGST(totals.subTotal);
          totals.total_SGST = findCGST_SGST(totals.subTotal);
          totals.total_IGST = findIGST(totals.subTotal);
          totals.Total = totals.total_IGST + totals.subTotal;
          totals.words = toWords.convert(totals.Total);

          pdfData.productsPriceList = productsPriceList;
          pdfData.totals = totals;

          if (pdfData.productsPriceList && pdfData.totals) {
            setAllFormData(pdfData);
            handlePopUp(props, pdfData);
          }
        }
      }
    }
  }

  const handlePopUp = (props: any, pdfData: any) => {

    if (invoiceApproveStatus.approve == true) {
      quoteProcess(props, pdfData, '0');
    }
    else {
      quoteProcess(props, pdfData, '1');
    }
  };
  const handleApproveClick = () => {
    apprveStatus = 1;
    dispatch(setQuoteApprovals(null));
    document.getElementById('sumbitBtn')?.click();
  }

  const rejectedQuote = () => {
    // alert(sessionStorage.editInvoiceID);
    let id = sessionStorage.editInvoiceID;
    let formData = new FormData();
    formData.append("invoice_id", id);

    let url = apiUrl + "rejectedQuote.php";

    // Fetch API to send form data
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "success") {
          console.log("Success:", data);
          rejectApproveProcess();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // setIsOpenPopup(true);
    // handleClick();
  }

  const quoteProcess = (props: any, pdfData: any, status: any) => {
    const jsonString = JSON.stringify(pdfData);
    const userID: any = sessionStorage.getItem("userID");

    let formData = new FormData();
    formData.append("company_name", pdfData.customerCompanyName);
    formData.append("quote_data", JSON.stringify(props));
    formData.append("invoice_data", jsonString);
    formData.append("created_by", userID);
    formData.append("status", status);

    let url = "";
    if (sessionStorage.editInvoiceID) {
      formData.append("invoice_id", sessionStorage.editInvoiceID);
      url = apiUrl + "editInvoice.php";
    } else {
      url = apiUrl + "saveInvoice.php";
    }

    // Fetch API to send form data
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "success") {
          console.log("Success:", data);
          if (status == '0') {
            sendManagerToApproveProcess();
          } else {
            // Do something with the response data if needed
            setIsOpenPopup(true);
            handleClick();
          }
          // setIsOpenPopup(true);
          // handleClick();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // setIsOpenPopup(true);
    // handleClick();
  }

  const rejectApproveProcess = () => {
    triggerNotification("success", "Success", "Quote Rejected successfully", "topRight");

    setTimeout(() => {
      navigate("/invoice_logs");
    }, 500);
  }

  const sendManagerToApproveProcess = () => {
    triggerNotification("success", "Success", "Quote approval requested sent successfully", "topRight");

    setTimeout(() => {
      navigate("/invoice_logs");
    }, 500);
  }

  const handleClick = () => {
    const message = apprveStatus == '1' ? 'Quote approved successfully' : 'Quote generated successfully';
    triggerNotification("success", "Success", message, "topRight");
  };

  const handleClose = () => {
    setIsOpenPopup(false);
  };

  const handleDateChange = (date: any, setFieldValue: any) => {
    if (date) {
      setFieldValue("expiryDate", date);
    } else {
      setFieldValue("expiryDate", "");
    }
  };
  const disabledDate = (current: any) => {
    return current && current < dayjs().startOf('day');
  };
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ values, setFieldValue, isSubmitting, handleSubmit }) => (
          <Form>
            <div className="grid grid-cols-12 gap-24">
              <div className="col-span-6">

                <div className="frm">
                  <label htmlFor="address" className="block z-10 mb-2 lab">Address</label>
                  <Field as="textarea" rows={2} id="address" name="address" className="block w-full p-2.5" placeholder="" />
                  <ErrorMessage name="address" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                </div>


                <div className="pt-5">
                  <div className="frm">
                    <label htmlFor="country" className="block z-10 mb-2 lab">Country</label>
                    <Field type="text" id="country" name="country" className="block w-full p-2.5" placeholder="" readOnly />
                    <ErrorMessage name="country" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                  </div>
                </div>

                <div className="pt-5">
                  <div className="frm">
                    <label htmlFor="state" className="block z-10 mb-2 lab1">State</label>
                    <Field
                      as={Select}
                      showSearch
                      suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                      className="w-[100%] text-[12px] xl:text-[16px] h-[42px] xl:h-[54px]"
                      name="state"
                      value={values.state}
                      onChange={(value: any) => setFieldValue("state", value)}
                      filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      placeholder="Select"
                    >
                      {options.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Field>
                    <ErrorMessage name="state" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                  </div>
                </div>

                <div className="grid grid-cols-12 gap-4 pt-5">
                  <div className="col-span-6">
                    <div className="frm">
                      <label htmlFor="city" className="block mb-2 lab">City</label>
                      <Field type="text" id="city" name="city" className="block w-full p-2.5" placeholder="" />
                      <ErrorMessage name="city" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                    </div>
                  </div>

                  <div className="col-span-6">
                    <div className="frm">
                      <label htmlFor="zipcode" className="block mb-2 lab">Zipcode</label>
                      <Field type="text" id="zipcode" name="zipcode" maxLength={6} className="block w-full p-2.5" placeholder="" />
                      <ErrorMessage name="zipcode" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-span-6">
                <div className="frm">
                  <label htmlFor="gstinNumber" className="block mb-2 lab">
                    GSTIN#
                  </label>
                  <Field type="text" id="gstinNumber" name="gstinNumber" className="block w-full p-2.5" placeholder="" />
                  <ErrorMessage name="gstinNumber" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                </div>

                <div className="grid grid-cols-12 gap-4 pt-5">
                  <div className="col-span-6">
                    {/* <div className="frm">
                      <label htmlFor="zipcode" className="block mb-2 lab">Zipcode</label>
                      <Field type="text" id="zipcode" name="zipcode" maxLength={6} className="block w-full p-2.5" placeholder="" />
                      <ErrorMessage name="zipcode" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                    </div> */}
                    <div className="frm">
                      <label htmlFor="mobileNumber" className="block mb-2 lab3">Mobile#</label>
                      <Field type="text" id="mobileNumber" name="mobileNumber" maxLength={10} className="block w-full p-2.5" placeholder="" />
                      <ErrorMessage name="mobileNumber" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                    </div>

                  </div>

                  <div className="col-span-6">
                    <div className="frm">
                      <label htmlFor="expiryDate" className="block z-10 mb-2 lab2">
                        Expiry Date
                      </label>

                      <DatePicker
                        id="expiryDate"
                        name="expiryDate"
                        className="block w-full p-2.5 h-[50px]"
                        onChange={(date) => handleDateChange(date, setFieldValue)}
                        defaultValue={dayjs()}
                        value={values.expiryDate ? dayjs(values.expiryDate, "DD-MM-YYYY") : null}
                        format={dateFormat}
                        disabledDate={disabledDate}
                      />
                      <ErrorMessage name="expiryDate" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="frm">
                    <label htmlFor="terms" className="block z-10 mb-2 lab">Terms & Conditions</label>
                    <Field as="textarea" rows={5} id="terms" name="terms" className="block w-full p-2.5" placeholder="" />
                    <ErrorMessage name="terms" component="div" className="text-red-500 text-[12px] xl:text-[15px]" />
                  </div>
                </div>

              </div>
            </div>

            <div className="dwn">
              {/* <button type="submit" className="flex items-center px-12 py-3 border border-[#BD44CA] rounded-[33.6px] border-1" disabled={isSubmitting}>
                <img src={share} alt="Share" className="pr-2" />
                Share
              </button> */}
              {
                sessionStorage.quoteStatus == '0' ? (
                  <>
                    <button type="button"
                      className="flex ml-3 items-center px-9 xl:px-12 py-2 xl:py-3 border bg-[#BD44CA] text-white rounded-[33.6px] border-1"
                      disabled={isSubmitting}
                      onClick={handleApproveClick}>
                      {/* <img src={pdf} alt="Download" className="pr-2" /> */}
                      Approve
                    </button>

                    <button type="button"
                      className="flex ml-3 items-center px-9 xl:px-12 py-2 xl:py-3 border bg-[#BD44CA] text-white rounded-[33.6px] border-1"
                      disabled={isSubmitting}
                      onClick={rejectedQuote}>
                      {/* <img src={pdf} alt="Download" className="pr-2" /> */}
                      Reject
                    </button>

                    <button type="submit"
                      id="sumbitBtn"
                      name="sumbitBtn"
                      disabled={isSubmitting}
                      style={{ display: 'none' }}>
                      <img src={pdf} alt="Download" className="pr-2" />
                      View PDF / Download
                    </button>
                  </>

                ) : (
                  <>
                    <button type="submit" className="flex ml-3 items-center px-9 xl:px-12 py-2 xl:py-3 border bg-[#BD44CA] text-white rounded-[33.6px] border-1" disabled={isSubmitting}>
                      <img src={pdf} alt="Download" className="pr-2" />
                      View PDF / Download
                    </button>
                  </>
                )
              }

            </div>
          </Form>
        )}
      </Formik>
      {isOpenPopup && <PdfView isOpenPopup={isOpenPopup} handleClose={handleClose}
        allFormData={allFormData} pdfType={props.rtoValue} />}
    </>
  );
};

export default BillingDetailForms;
