import { createSlice } from "@reduxjs/toolkit";

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: { approve: null },
    reducers: {
        setQuoteApprovals(state, action) {
            state.approve = action.payload;
        },
        getQuoteApprovals(state, action) {
            return state.approve;
        },
    },
});

export const { setQuoteApprovals, getQuoteApprovals } = invoiceSlice.actions;
export default invoiceSlice.reducer;