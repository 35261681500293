// formHelpers.ts

// Define the type for form values
export interface FormValues {
    productType: string;
    subPlan: string;
    licenses: string;
    googlePrice: string;
    customerPrice: string;
}

// Define the type for form errors
export interface FormErrors {
    productType?: string;
    subPlan?: string;
    licenses?: string;
    googlePrice?: string;
    customerPrice?: string;
}

export const initialFormValues: FormValues = {
    productType: "business",
    subPlan: "starter",
    licenses: "",
    googlePrice: "",
    customerPrice: "",
};

export const subplans: any = {
    business: ["Starter", "Standard", "Plus"],
    enterprise: ["Starter", "Standard", "Plus"],
    frontline: ["Basic", "Plus"],
    akku: ["Plan 1", "Plan 2", "Plan 3", "Plan 4"],
};

export const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const charCode = e.charCode;
    const char = String.fromCharCode(charCode);
    if (!/^\d$/.test(char) && char !== ".") {
        e.preventDefault();
    }
};

