// styles.js
import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#E4E4E4",
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    pageFirst: {
        fontFamily: "Helvetica",
        fontSize: 11,
        lineHeight: 1.5,
        flexDirection: "column",
        position: "relative", // Positioning relative to the page
    },
    pageFirstBackgroundImage: {
        position: "absolute", // Ensures image occupies the entire page
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        zIndex: -1
    },
    pageSecond: {
        fontFamily: "Helvetica",
        flexDirection: "column",
        backgroundColor: "#f9f9f9",
        justifyContent: "space-between",
        height: "100%",
    },
    pageSecondContent1: {
        height: 710,
    },
    pageSecondHeaderLogo: {
        width: 140,
    },
    pageSecondHeader: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        paddingTop: 30,
        paddingLeft: 29,
        paddingRight: 29,
    },
    pageSecondRow: {
        display: "flex",
        flexDirection: "row",
        paddingTop: 165,
        paddingLeft: 29,
        paddingRight: 29,
        backgroundColor: "#f9f9f9",
    },
    pageSecondLeft: {
        width: "32%",
    },
    pageSecondContent2: {
        height: 100,
    },
    cnwOrganisationAddress: {
        marginTop: 0,
    },
    designation: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "semibold",
    },
    streetNumber: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
        paddingTop: 7,
    },
    place: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    area: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    statePincode: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    textIndia: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "semibold",
    },
    pageSecondRightContent: {
        marginTop: 0,
    },
    dearContent: {
        color: "#231F20",
        marginTop: 25,
        fontWeight: "normal",
        fontSize: 9,
    },
    paragraph1: {
        color: "#231F20",
        fontWeight: "normal",
        fontSize: 9,
        paddingTop: 10,
        paddingRight: 10,
    },
    paragraph2: {
        color: "#231F20",
        fontWeight: "normal",
        fontSize: 9,
        paddingTop: 10,
        paddingRight: 10,
    },
    paragraph2Management: {
        color: "#231F20",
        fontWeight: "normal",
        fontSize: 9,
    },
    paragraph3: {
        color: "#231F20",
        fontWeight: "normal",
        fontSize: 9,
        paddingTop: 10,
        paddingRight: 10,
    },
    paragraph4: {
        color: "#231F20",
        fontWeight: "normal",
        fontSize: 9,
        paddingTop: 10,
        paddingRight: 10,
    },
    signatureImage: {
        paddingTop: 25,
    },
    name: {
        color: "#231F20",
        fontWeight: "normal",
        fontSize: 8,
        paddingTop: 25,
    },
    companyname: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    mailAddress: {
        color: "#673BAD",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
        textDecoration: "underline",
    },
    signatureLogo: {
        width: 150,
        paddingTop: 60,
    },
    pageSecondRight: {
        width: "68%",
        paddingLeft: 10,
    },
    pageThankyou: {
        fontFamily: "Helvetica",
        fontSize: 11,
        lineHeight: 1.5,
        flexDirection: "column",
        position: "relative", // Positioning relative to the page
    },
    pageThankyouBackgroundImage: {
        position: "absolute", // Ensures image occupies the entire page
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
    },
    pageThird: {
        fontFamily: "Helvetica",
        fontSize: 11,
        lineHeight: 1.5,
        flexDirection: "column",
        position: "relative", // Positioning relative to the page
    },
    pageThirdBackgroundImage: {
        position: "absolute", // Ensures image occupies the entire page
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        flexDirection: "row",
    },
    titleContainer: {
        flexDirection: "column",
    },
    reportTitle: {
        color: "#D64B87",
        letterSpacing: 1,
        fontSize: 23,
        textAlign: "center",
        fontWeight: 900,
    },
    reportId: {
        color: "#888888",
        fontSize: 9,
        textAlign: "center",
        fontWeight: 900,
        marginTop: 3,
    },
    headerContainer: {
        marginTop: 5,
    },
    BillFrom: {
        marginTop: 20,
        paddingBottom: 3,
    },
    textLite: {
        color: "#888888",
        fontWeight: "medium",
        fontSize: 8,
        marginTop: 3
    },
    fromCompany: {
        color: "#1F3C66",
        fontWeight: "medium",
        fontSize: 10,
        marginTop: 2
    },
    toCompany: {
        color: "black",
        fontWeight: "medium",
        fontSize: 10,
        marginTop: 3,
        marginBottom: 2
    },
    text1: {
        color: "#757779",
        fontWeight: "medium",
        fontSize: 8,
        marginTop: 3
    },
    text2: {
        color: "#757779",
        fontWeight: "medium",
        fontSize: 8,
        marginTop: 3
    },
    text3: {
        color: "#757779",
        fontWeight: "medium",
        fontSize: 8,
        marginTop: 3
    },
    text4: {
        color: "#757779",
        fontWeight: "medium",
        fontSize: 8,
        marginTop: 3
    },
    text5: {
        color: "#757779",
        fontWeight: "medium",
        fontSize: 8,
        paddingTop: 3
    },
    pageFour: {
        paddingTop: 25,
        paddingLeft: 29,
        paddingRight: 29,
        backgroundColor: "#f9f9f9",
    },
    content1: {
        width: "60%",
    },
    billTo: {
        marginTop: 10,
        color: "#797F84",
        fontWeight: "medium",
        fontSize: 10,
    },
    placeOfSupply: {
        color: "#888888",
        fontWeight: "medium",
        fontSize: 9,
        paddingTop: 10,
    },
    content2: {
        width: "40%",
        justifyContent: "center", // Centers content vertically
    },
    estimateDate: {
        color: "#797F84",
        fontWeight: "medium",
        fontSize: 10,
        textAlign: "right",
    },
    expiryDate: {
        color: "#797F84",
        fontWeight: "medium",
        fontSize: 10,
        textAlign: "right",
        paddingTop: 5,
        // backgroundColor: "#435345", it is for testing
        marginLeft: 2
    },
    dateValue: {
        paddingRight: 50,
        paddingLeft: 50,
        fontSize: 10,
        color: "#565656"
    },
    headerContainer2: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        flexDirection: "row",
    },
    notesTitle: {
        color: "#797F84",
        fontWeight: "medium",
        fontSize: 10,
        paddingTop: 40,
        marginBottom: 5,

    },
    notesDescription: {
        color: "#333",
        fontSize: 9,
        fontWeight: "semibold",

    },
    notesContent: {
        paddingTop: 5,
        color: "#565656",
        fontWeight: "normal",
        fontSize: 8,
        paddingRight: 10,
    },
    billTo1: {
        color: "#888888",
        fontWeight: "medium",
        fontSize: 10,
        paddingTop: 10,
    },
    notes: {
        color: "#888888",
        fontWeight: "medium",
        fontSize: 10,
        paddingTop: 10,
    },
    line: {
        borderBottom: 1,
        borderColor: "#1F3C66",
        width: "100%",
        paddingTop: 10,
    },
    image1: {
        width: 150,
        paddingBottom: 10,
    },
    image2: {
        width: 200,
        paddingBottom: 10,
    },
    col1: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col2: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col3: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col4: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col5: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col6: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col7: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col8: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col9: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col10: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col11: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col12: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col13: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col14: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col15: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    col16: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    textTitle: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "semibold",
        paddingTop: 10,
    },
    heading4: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    heading2: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "semibold",
    },
    heading5: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    heading6: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    heading7: {
        color: "#231F20",
        fontSize: 8,
        textAlign: "left",
        fontWeight: "normal",
    },
    headerContainer1: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        flexDirection: "row",
    },
    content3: {
        paddingTop: 15,
        flexDirection: "column",
    },
    textAlign: {
        textAlign: "right",
    },
    content1Address: {
        color: "#797F84",
        fontWeight: "medium",
        fontSize: 11,
    },
    customerName: {
        color: '#1F3C66',
        fontWeight: "medium",
    }
});

export default styles;
